(function ($) {
  Drupal.behaviors.gnavToutV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $template = $('.js-gnav-tout-v1', context);

      $template.each(function () {
        var $this = $(this);
        var $ratingStars = $('.js-gnav-tout-content-wrapper-rating-stars', $this);
        var ratingPercentage = $ratingStars.data('rating-stars');
        var percentageStar;

        if (ratingPercentage && ratingPercentage !== 'rating-stars-default') {
          ratingPercentage = parseFloat(ratingPercentage).toFixed(2);
          for (var i = 1; i <= ratingPercentage; i++) {
            if (i <= ratingPercentage) {
              $('.gnav-tout__content-wrapper-rating-stars-' + i, $this)
                .removeClass('pr-star-v4-0-filled')
                .addClass('pr-star-v4-100-filled');
              percentageStar = ratingPercentage - i;
              if (percentageStar < 1) {
                $('.gnav-tout__content-wrapper-rating-stars-' + (i + 1), $this)
                  .removeClass('pr-star-v4-0-filled')
                  .addClass('pr-star-v4-' + percentageStar * 100 + '-filled');
              }
            }
          }
        }
      });
    }
  };
})(jQuery);
